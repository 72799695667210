import axios from 'axios';

// export const baseURL = 'https://www.peddle.store';
// export const host = 'https://www.peddle.store/api';
// export const baseURL = 'http://192.168.1.100:8080/admin';
// export const host = 'http://192.168.1.100:8080/admin/api';
export const baseURL = '/';
export const host = '/api';

const api = axios.create({
  baseURL: host,
  // headers: {
  //   token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ijk4NTFiNDcyLTZmYjAtNDBjMS1hMDZiLTI3YmE1YWEzNjgyMCIsInJvbGUiOiJBRE1JTiIsImlhdCI6MTYxOTY2MDYyMH0.sYy2C08Z2JMisuzv56YS4pKU3MgdCKOu3MmGT4OB9bU"
  // }
});

export default api;
